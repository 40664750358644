<template>
  <ul class="infinite-list">
    <li class="infinite-list-item">
      <div class="flex align-items">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkedAll" @change="changeAll"></el-checkbox>
        <div class="flex align-items justify-content-space-between"
             style="width: 100%;"
        >
          <div
              @click.stop="handleOne(!checkedAll)"
              style="user-select: none"
              :style="{ color: checkedAll ? '#409eff' : '' }"
          >
            全选
          </div>
        </div>
      </div>
    </li>
    <li v-for="(item,index) in regionData" :key="index"
        :style="{ backgroundColor: item.single ? '#409eff' : '#FFFFFF' }"
        class="infinite-list-item">
      <div class="flex align-items">
        <el-checkbox v-model="item.check" :indeterminate="item.isIndeterminate" @change="changeSingle($event,item.id)"></el-checkbox>
        <div class="flex align-items justify-content-space-between"
             style="width: 100%;"
             @click="handleSingle(item.id)"
        >
          <div :style="{
                        color: item.single ? '#FFFFFF'
                        : !item.single && item.check ? '#409eff'
                        : !item.single && !item.check ? '#303133'
                        : ''
                      }"
               style="user-select: none"
               @click.stop="changeSingle(!item.check,item.id)"
          >
            {{ item.name }}
          </div>
          <i class="el-icon-arrow-right"
             :style="{
                        color: item.single ? '#FFFFFF'
                        : !item.single && item.check ? '#409eff'
                        : !item.single && !item.check ? '#303133'
                        : ''
                      }"></i>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "second",
  props: {
    regionData: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      checkedAll: false,
      isIndeterminate: true
    }
  },
  watch: {
    regionData(val){
      // // console.log('second',val)
      this.$nextTick(()=>{
        val.length > 0 && this.getCheckedAll(val)
      })
    }
  },
  mounted() {
    // // console.log('second',this.regionData)
    this.$nextTick(()=>{
      this.regionData.length > 0 && this.getCheckedAll(this.regionData)
    })
  },
  methods:{
    getCheckedAll(array){
      const checkData = array.map(item => {
        return item.check ? true : null
      }).filter(item=>item)
      const isIndeterminateData = array.map(item => {
        return item.isIndeterminate ? true : null
      }).filter(item=>item)
      if(isIndeterminateData.length > 0){
        this.isIndeterminate = true
      }else{
        if(checkData.length === 0){
          this.isIndeterminate = false
        }else{
          this.isIndeterminate = !array.every(item=>item.check)
        }
      }
      this.checkedAll = array.every(item=>item.check)
    },
    changeAll(e){
      // console.error(e)
      const array = this.regionData.map(item => {
        const { check,children, ...rest } = item;
        return {
          check: e,
          children: children.map(item=> {
            const { check, ...rest } = item;
            return {
              check: e,
              ...rest
            }
          }),
          ...rest
        }
      })
      // console.error(array)
      this.isIndeterminate = false
      this.$emit('change',{ data: array, isIndeterminate: this.isIndeterminate })
    },
    handleOne(e){
      this.checkedAll = e
      this.isIndeterminate = false
      const array = this.regionData.map(item => {
        const { check, ...rest } = item;
        return {
          check: e,
          ...rest
        }
      })
      this.$emit('change',{ data: array, isIndeterminate: this.isIndeterminate })
    },
    // 点击选择当前级
    async changeSingle(e,id){
      // // console.log(e,id)
      const array = await this.setArray(e,id)
      // // console.log('array',array)
      const arrayNew = array.map(item => {
        return item.check ? true : null
      }).filter(item=>item)
      // // console.log('arrayNew',arrayNew)
      if(arrayNew.length === 0){
        this.isIndeterminate = false
      }else{
        this.isIndeterminate = !array.every(item=>item.check)
      }
      // // console.log(array)
      this.checkedAll = array.every(item=>item.check)
      this.$emit('change',{ data: array, isIndeterminate: this.isIndeterminate })
    },
    setArray(e,id){
      return new Promise(resolve => {
        resolve(this.regionData.map(item => {
          const { check,children,isIndeterminate, ...rest } = item;
          // // console.log(check)
          const data = item.id === id && this.setCheck(children,e)
          return item.id === id ? {
            check: e,
            isIndeterminate: false,
            children: data,
            ...rest
          } : {
            check,
            isIndeterminate,
            children,
            ...rest
          }
        }))
      })
    },
    setCheck(data,e){
      return data.map(item=> {
        const { check, children, ...rest } = item;
        return children ? {
          check: e,
          children: this.setCheck(children),
          ...rest
        } : {
          check: e,
          ...rest
        }
      })
    },
    // 点击展示下级,带背景
    handleSingle(id){
      this.all = false;
      // // console.log('this.regionData',this.regionData)
      const array = this.regionData.map(item => {
        const { single, ...rest } = item;
        return {
          single: item.id === id,
          ...rest
        }
      })
      // // console.log(array)
      this.$emit('change',{ data: array, isIndeterminate: this.isIndeterminate })
    },
  }
}
</script>

<style lang="scss" scoped>

.region{
  .infinite-list{
    height: 40vh;
    overflow:auto;
    border: 1px solid #EBEEF5;
    padding: 10px 0;
    .infinite-list-item{
      line-height: 34px;
      border-radius: 3px;
      padding: 0 10px;
      cursor: pointer;
    }
    .hover{
      &:hover{
        background-color: #409EFF;
        color: #FFFFFF !important;
      }
    }
  }
}

</style>