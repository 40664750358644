<template>
  <div class="main-content">
    <h3 class="marginBottom20">
      {{ type === "create" ? "新增" : "编辑" }}运费模板
    </h3>

    <el-form
      ref="form"
      :model="form"
      label-width="150px"
      :rules="rules"
      size="small"
    >
      <el-form-item label="模板名称: " prop="name">
        <el-input
          class="width500"
          v-model="form.name"
          clearable
          placeholder="请输入模板名称"
          :maxlength="15"
          show-word-limit
        />
      </el-form-item>

      <el-form-item label="模板类型: " prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">买家承担</el-radio>
          <el-radio :label="2">卖家包邮</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="包邮金额: " prop="free" v-if="form.type === 1">
         <el-input placeholder="请输入包邮金额" v-model="form.free" />
      </el-form-item>

      <el-form-item label="计费方式: " prop="billType" v-if="form.type === 1">
        <el-radio-group v-model="form.billType">
          <el-radio :label="1">按重量</el-radio>
          <el-radio :label="2">按件数</el-radio>
          <el-radio :label="3">按体积</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="配送区域及运费: " prop="isDefaultDelivery">
        <el-checkbox
          v-model="form.isDefaultDelivery"
          :disabled="form.type === 2"
          :true-label="1"
          :false-label="2"
          @change="changeIsDefaultDelivery"
        >
          所有地区默认配送
          <span style="color: #7f7f7f" v-if="form.type === 1"
            >(未勾选，则仅下列所设区域可配送)</span
          >
          <span style="color: #7f7f7f" v-if="form.type === 2"
            >(卖家包邮后所有地区均可配送，且买家无需支付邮费)</span
          >
        </el-checkbox>
      </el-form-item>

      <el-form-item
        label=""
        label-width="0"
        prop="regionList"
        v-if="form.type === 1"
      >
        <el-table
          :data="form.regionList"
          border
          :header-cell-style="{ background: '#F3F4F7', color: '#555' }"
          size="small"
          style="width: 100%"
        >
          <el-table-column
            key="1"
            prop="regionDetails"
            label="配送区域"
            align="center"
          >
            <template slot-scope="scope">
              <div class="flex align-items" v-if="form.isDefaultDelivery === 2">
                <div style="width: 75%">
                  <div>
                    {{
                      handleRegion(form.regionList[scope.$index].regionDetails)
                    }}
                  </div>
                </div>
                <div
                  class="flex align-items justify-content-center"
                  style="width: 25%"
                >
                  <el-button
                    type="text"
                    @click="handleEdit(scope.row, scope.$index, 'edit')"
                    >编辑</el-button
                  >
                  <el-button type="text" @click="handleDelete(scope.$index)"
                    >删除</el-button
                  >
                </div>
              </div>
              <div v-else class="flex align-items justify-content-center">
                <div>全国</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            key="2"
            v-if="form.billType === 1"
            prop="firstWeight"
            label="首重（g）"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.firstWeight`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].firstWeight"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'firstWeight'),
                      (index = scope.$index),
                      (max = 999999)
                    )
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="3"
            v-if="form.billType === 2"
            prop="firstArticle"
            label="首件(个)"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.firstArticle`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].firstArticle"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'firstArticle'),
                      (index = scope.$index),
                      (max = 999999)
                    )
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="4"
            v-if="form.billType === 3"
            prop="firstVolume"
            label="首体积(m³)"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.firstVolume`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].firstVolume"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'firstVolume'),
                      (index = scope.$index),
                      (max = 999999)
                    )
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="5"
            prop="firstPrice"
            label="首费（元）"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.firstPrice`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].firstPrice"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'firstPrice'),
                      (index = scope.$index),
                      (max = 999999.99)
                    )
                  "
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="6"
            v-if="form.billType === 1"
            prop="continueWeight"
            label="续重（g）"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.continueWeight`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].continueWeight"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'continueWeight'),
                      (index = scope.$index),
                      (max = 999999)
                    )
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="7"
            v-if="form.billType === 2"
            prop="continueArticle"
            label="续件（个）"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.continueArticle`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].continueArticle"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'continueArticle'),
                      (index = scope.$index),
                      (max = 999999)
                    )
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="8"
            v-if="form.billType === 3"
            prop="continueVolume"
            label="续体积（m³）"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.continueVolume`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].continueVolume"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'continueVolume'),
                      (index = scope.$index),
                      (max = 999999)
                    )
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
            key="9"
            prop="continuePrice"
            label="续费（元）"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="`regionList.${scope.$index}.continuePrice`"
                :show-message="false"
                :rules="[
                  {
                    required: scope.$index !== form.regionList.length - 1,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.regionList[scope.$index].continuePrice"
                  placeholder="请输入"
                  clearable
                  @blur="
                    changeBlur(
                      $event,
                      (label = 'continuePrice'),
                      (index = scope.$index),
                      (max = 999999.99)
                    )
                  "
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="flex align-items justify-content-center"
          style="height: 50px; border: 1px solid #ebeef5; border-top: none"
        >
          <el-button
            type="text"
            :disabled="form.isDefaultDelivery === 1"
            @click="regionAdd('create')"
            >新增配送区域及运费</el-button
          >
        </div>
      </el-form-item>

      <el-form-item label-width="0">
        <el-button
          type="primary"
          :loading="loading"
          @click="submit"
          style="padding-left: 30px; padding-right: 30px"
          >确定</el-button
        >
        <el-button
          @click="$router.go(-1)"
          style="padding-left: 30px; padding-right: 30px"
          >取消</el-button
        >
      </el-form-item>
    </el-form>

    <Region
      ref="region"
      v-if="show"
      :show="show"
      :type="showType"
      :index="showIndex"
      :regionData="regionData"
      :regionDataShow="regionDataShow"
      @submit="handleSubmit"
      @change="changeRegionData"
      @close="handleClose"
    />
  </div>
</template>

<script>
import { add, region, update, view } from "@/api/freight/freight";
import { rules } from "./data";
import Region from "./region";

export default {
  name: "create",
  components: {
    Region,
  },
  data() {
    return {
      type: this.$route.query.type,
      loading: false,
      form: {
        name: "",
        type: 1,
        billType: 1,
        isDefaultDelivery: 2,
        regionList: [],
      },
      rules,
      cacheRegionListOne: [
        {
          region: [],
          firstWeight: "",
          firstArticle: "",
          firstVolume: "",
          firstPrice: "",
          continueWeight: "",
          continueArticle: "",
          continueVolume: "",
          continuePrice: "",
        },
      ],
      cacheRegionListTwo: [],

      regionData: [],
      regionDataDefault: [],
      show: false,
      showType: "",
      showIndex: 0,
      regionDataShow: [],
    };
  },
  async mounted() {
    this.$route.query.id && (await this.getView());
    await this.getRegion();
  },
  methods: {
    async getView() {
      this.loading = true;
      const res = await view({ id: this.$route.query.id });
      // // console.log(res)
      if (res.code === 200) {
        const {
          id,
          name,
          type,
          free,
          billType,
          isDefaultDelivery,
          regionList,
          ...rest
        } = res.data;
        regionList.map((item) => {
          if (item.regionDetailsId) {
            item.regionDetailsId = item.regionDetailsId.split(",");
          }
        });
        this.form = {
          id,
          name,
          type,
          billType,
          free,
          isDefaultDelivery,
          regionList:
            isDefaultDelivery === 1
              ? [
                  {
                    firstWeight: res.data.firstWeight,
                    firstArticle: res.data.firstArticle,
                    firstVolume: res.data.firstVolume,
                    firstPrice: res.data.firstPrice,
                    continueWeight: res.data.continueWeight,
                    continueArticle: res.data.continueArticle,
                    continueVolume: res.data.continueVolume,
                    continuePrice: res.data.continuePrice,
                  },
                ]
              : regionList,
        };
        if (isDefaultDelivery === 1) {
          this.cacheRegionListOne = this.form.regionList;
        } else {
          this.cacheRegionListTwo = this.form.regionList;
          this.cacheRegionListOne = [
            {
              firstWeight: "",
              firstArticle: "",
              firstVolume: "",
              firstPrice: "",
              continueWeight: "",
              continueArticle: "",
              continueVolume: "",
              continuePrice: "",
            },
          ];
        }
      } else {
        this.$message.error(res.msg);
        this.$router.go(-1);
      }
      this.loading = false;
    },
    async getRegion() {
      const { data } = await region();
      const array = data.map((item) => {
        return item.children && item.children.length > 0
          ? {
              check: false,
              ...item,
            }
          : {};
      });
      this.regionDataDefault = this.setRegionData(data);
    },
    setRegionData(data, level = 1) {
      return data.map((item) => {
        const { children, ...rest } = item;
        return {
          ...rest,
          check: false,
          single: false,
          isIndeterminate: false,
          children:
            children &&
            children.length > 0 &&
            this.setRegionData(children, level + 1),
          level,
        };
      });
    },
    regionAdd(type) {
      this.show = true;
      this.showType = type;
      this.regionData = JSON.parse(JSON.stringify(this.regionDataDefault));
    },
    handleDelete(index) {
      if (this.form.regionList.length === 1) {
        this.$message.warning("最少保留一个配送区域");
      } else {
        this.form.regionList.splice(index, 1);
      }
    },
    changeIsDefaultDelivery(e) {
      const { regionList, ...form } = this.form;
      if (e === 1) {
        this.cacheRegionListTwo = JSON.parse(JSON.stringify(regionList));
        this.form.regionList = this.cacheRegionListOne;
      } else {
        this.cacheRegionListOne = JSON.parse(JSON.stringify(regionList));
        this.form.regionList = this.cacheRegionListTwo;
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let info = {};
          this.loading = true;
          if (this.form.type === 1) {
            const ids = this.form.regionList
              .map((item) => item.regionDetailsId)
              .flat();
            if (new Set(ids).size !== ids.length) {
              this.$message.warning("配送区域不能重复");
              this.loading = false;
              return;
            }
            if (
              this.form.isDefaultDelivery === 2 &&
              this.form.regionList.length === 0
            ) {
              this.$message.warning("请至少添加一个配送区域及运费");
              this.loading = false;
              return;
            }
            if (this.form.billType === 1) {
              if (
                this.form.regionList.some((item) => item.firstWeight === "")
              ) {
                this.$message.warning("请填写首重");
                this.loading = false;
                return;
              }
              if (
                this.form.regionList.some((item) => item.continueWeight === "")
              ) {
                this.$message.warning("请填写续重");
                this.loading = false;
                return;
              }
            }
            if (this.form.billType === 2) {
              if (
                this.form.regionList.some((item) => item.firstArticle === "")
              ) {
                this.$message.warning("请填写首件");
                this.loading = false;
                return;
              }
              if (
                this.form.regionList.some((item) => item.continueArticle === "")
              ) {
                this.$message.warning("请填写续件");
                this.loading = false;
                return;
              }
            }
            if (this.form.billType === 3) {
              if (
                this.form.regionList.some((item) => item.firstVolume === "")
              ) {
                this.$message.warning("请填写首体积");
                this.loading = false;
                return;
              }
              if (
                this.form.regionList.some((item) => item.continueVolume === "")
              ) {
                this.$message.warning("请填写续体积");
                this.loading = false;
                return;
              }
            }
            if (this.form.regionList.some((item) => item.firstPrice === "")) {
              this.$message.warning("请填写首费");
              this.loading = false;
              return;
            }
            if (
              this.form.regionList.some((item) => item.continuePrice === "")
            ) {
              this.$message.warning("请填写续费");
              this.loading = false;
              return;
            }
            if (this.form.isDefaultDelivery === 1) {
              info = {
                firstWeight: this.form.regionList[0].firstWeight,
                firstArticle: this.form.regionList[0].firstArticle,
                firstVolume: this.form.regionList[0].firstVolume,
                firstPrice: this.form.regionList[0].firstPrice,
                continueWeight: this.form.regionList[0].continueWeight,
                continueArticle: this.form.regionList[0].continueArticle,
                continueVolume: this.form.regionList[0].continueVolume,
                continuePrice: this.form.regionList[0].continuePrice,
              };
            }
          }
          console.log('this.form.regionList',this.form.regionList)

          this.form.regionList.map((item) => {
            item.regionDetailsId = item.regionDetailsId?item.regionDetailsId.toString():'';
          });
          if (this.form.id) {
            update({
              ...this.form,
              ...info,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.$router.go(-1);
                } else {
                  this.form.regionList.map((item) => {
                    item.regionDetailsId = item.regionDetailsId.split(",");
                  });
                  this.$message.error(res.msg);
                  this.loading = false;
                }
              })
              .catch(() => {
                this.form.regionList.map((item) => {
                  item.regionDetailsId = item.regionDetailsId.split(",");
                });
                this.loading = false;
              });
          } else {
            add({
              ...this.form,
              ...info,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("添加成功");
                  this.$router.go(-1);
                } else {
                  // this.$message.error(res.msg)
                  this.form.regionList.map((item) => {
                    item.regionDetailsId = item.regionDetailsId.split(",");
                  });
                  this.loading = false;
                }
              })
              .catch(() => {
                console.log("error submit!!");
                this.form.regionList.map((item) => {
                  item.regionDetailsId = item.regionDetailsId.split(",");
                });
                this.loading = false;
              });
          }
        } else {
          // // console.log('error submit!!');
          return false;
        }
      });
    },
    /* 输入框校验 */
    changeBlur(e, label, index, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value)) {
          e.target.value = "";
          this.form.regionList[index][label] = "";
        } else {
          if (max) {
            if (Number(value) > Number(max)) {
              this.form.regionList[index][label] = Number(max);
            } else {
              this.form.regionList[index][label] = Number(value);
            }
          } else {
            this.form.regionList[index][label] = value;
          }
        }
      } else {
        this.form.regionList[index][label] = "";
        e.target.value = "";
      }
      // console.log(this.form.regionList)
    },
    async handleEdit(row, index, type) {
      // // console.log(row,index)
      console.table(row.regionDetails);
      this.showIndex = index;
      this.showType = type;
      this.regionDataShow = row.regionDetails;
      this.regionData = await this.setEditRegionData(row.regionDetails);
      this.show = true;
    },
    setEditRegionData(data) {
      const defaultData = JSON.parse(JSON.stringify(this.regionDataDefault));
      // return defaultData.map(item=> {
      //   const info = data.find(a=>item.id === a.id)
      //   return !!info ? data.find(a=>item.id === a.id) : item
      // })
      return defaultData.map((item) => {
        const info = data.find((a) => item.id === a.id);
        if (!!info) {
          const { children, ...rest } = info;
          const { children: childrenDefault, ...restDefault } = item;
          return {
            ...rest,
            children:
              childrenDefault &&
              childrenDefault.length > 0 &&
              childrenDefault.map((a) => {
                const info2 = children.find((b) => a.id === b.id);
                if (!!info2) {
                  const { children: children2, ...rest2 } = info2;
                  const { children: childrenDefault2, ...restDefault2 } = a;
                  return {
                    ...rest2,
                    children:
                      childrenDefault2 &&
                      childrenDefault2.length > 0 &&
                      childrenDefault2.map((aa) => {
                        const info3 = children2.find((bb) => aa.id === bb.id);
                        if (!!info3) {
                          const { children: children3, ...rest3 } = info3;
                          const {
                            children: childrenDefault3,
                            ...restDefault3
                          } = aa;
                          return {
                            ...rest3,
                            children: childrenDefault3,
                          };
                        } else {
                          return aa;
                        }
                      }),
                  };
                } else {
                  return a;
                }
              }),
          };
        } else {
          return item;
        }
      });
    },
    handleSubmit(e) {
      // console.log('保存的数据',e)
      const dataNew = e.data.map((item) => {
        const { single, children, ...one } = item;
        return {
          ...one,
          single: false,
          children: children.map((itemSon) => {
            const { single, ...two } = itemSon;
            return {
              ...two,
              single: false,
            };
          }),
        };
      });
      const dataId = dataNew
        .map((a) => {
          const firstId = a.id;
          const secondId =
            a.children && a.children.length > 0
              ? a.children.map((b) => b.id)
              : [];
          const thirdId =
            a.children && a.children.length > 0
              ? a.children.map((b) =>
                  b.children && b.children.length > 0
                    ? b.children.map((d) => d.id)
                    : []
                )
              : [];
          return [firstId, ...secondId.flat(), ...thirdId.flat()].flat();
        })
        .flat();
      if (e.type === "create") {
        this.form.regionList.splice(-1, 0, {
          regionDetails: dataNew,
          regionDetailsId: dataId,
          firstWeight: "",
          firstArticle: "",
          firstVolume: "",
          firstPrice: "",
          continueWeight: "",
          continueArticle: "",
          continueVolume: "",
          continuePrice: "",
        });
      } else {
        this.form.regionList[e.index].regionDetails = dataNew;
        this.form.regionList[e.index].regionDetailsId = dataId;
      }
      this.show = false;
      this.regionData = [];
      this.regionDataShow = [];
    },
    handleClose() {
      this.show = false;
      this.regionData = [];
      this.regionDataShow = [];
    },
    changeRegionData(e) {
      this.regionData = e;
    },
    handleRegion(e) {
      const firstNewData = e.map((item) => {
        return item.check
          ? [
              {
                firstName: item.name,
                secondName: null,
                thirdName: null,
              },
            ]
          : this.handleRegionTwoText(item.name, item.children);
      });
      return firstNewData
        .map((item) => {
          return item
            .map((itemSon) => {
              const { firstName, secondName, thirdName } = itemSon;
              return `${firstName}${
                !!secondName ? `(${secondName}${thirdName || ""})` : ""
              }`;
            })
            .join("、");
        })
        .join("、");
    },
    handleRegionTwoText(name, data) {
      // // console.log(data)
      return data.map((item) => {
        return item.check
          ? {
              firstName: name,
              secondName: item.name,
              thirdName: null,
            }
          : {
              firstName: name,
              secondName: item.name,
              thirdName: item.children.map((item) => item.name).join("、"),
            };
      });
    },
  },
};
</script>

<style scoped>
/deep/ .el-checkbox__inner {
  margin-bottom: 3px;
}

.el-table /deep/ .el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}
</style>