import { get, put,Delete,post } from '../axios'
export const list = params => get('/order/web/freight/manage/list', params)

export const region = params => get('/order/web/freight/manage/region/list', params)

export const add = params => post('/order/web/freight/manage/add', params)

export const deleteNew = params => Delete(`/order/web/freight/manage/delete/${params.id}`, '')

export const view = params => get(`/order/web/freight/manage/view/${params.id}`,'')

export const update = params => put('/order/web/freight/manage/update', params)

export const stateUp = params => put('/order/web/freight/manage/state/update', params)