export const indexOption = {
    title:'',
    titleSize:'h3',
    card: true,
    border: false,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: true, // 显示索引
    indexLabel: '序号',
    indexWidth: 150,
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    sizes:20,
    align:'center',
    menuAlign:'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '模板名称',
            prop: 'name'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        },
        {
            label: '状态',
            prop: 'state'
        }
    ]
}
export const rules = {
    name: [
        { required: true, message: '请输入模板名称', trigger: 'blur' }
    ],
    type: [
        { required: true, message: '请选择模板类型', trigger: 'change' }
    ],
    billType: [
        { required: true, message: '请选择计费方式', trigger: 'change' }
    ]
}