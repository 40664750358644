<template>
  <el-dialog
      class="region"
      title="提示"
      :visible.sync="show"
      width="60%"
      :before-close="handleClose">
    <el-row :gutter="24">
      <el-col :span="18">
        <el-card shadow="never">
          <template #header>
            <h4>可选省、市、区</h4>
          </template>

          <el-row :gutter="24">
            <el-col :span="8">
              <First :regionData="regionData" @change="handleChangeOne"/>
            </el-col>
            <el-col :span="8">
              <Second v-if="regionDataTwo.length > 0" :regionData="regionDataTwo" @change="handleChangeTwo"/>
            </el-col>
            <el-col :span="8">
              <Third v-if="regionDataThree.length > 0" :regionData="regionDataThree" @change="handleChangeThree"/>
            </el-col>
          </el-row>

        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="never">
          <template #header>
            <h4>已选省、市、区</h4>
          </template>
          <el-tree :data="regionDataNew" :props="defaultProps"></el-tree>
        </el-card>
      </el-col>
    </el-row>
    <div class="flex align-items justify-content-flex-end marginTop20">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import First from './component/first'
import Second from './component/second'
import Third from './component/third'
export default {
  name: "region",
  components: {
    First,
    Second,
    Third
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    regionData: {
      type: Array,
      default: () => []
    },
    regionDataShow: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data(){
    return{
      regionDataTwo: [],
      regionDataThree: [],
      regionDataNew: this.regionDataShow,
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  watch: {
    regionData(val){
      if(val){
        const data = this.regionData.find(item=>item.single)
        // // console.log('data',data)
        if(data){
          this.regionDataTwo = data.children;
          const dataTwo = data.children.find(item=>item.single)
            // // console.log('dataTwo',dataTwo)
          if(dataTwo){
            this.regionDataThree = dataTwo.children;
          }else{
            this.regionDataThree = [];
          }
        }else{
          this.regionDataTwo = [];
          this.regionDataThree = []
        }
        this.regionDataNew = this.selected(val)
        // // console.log('最新数组',this.regionDataNew)
      }
    }
  },
  methods: {
    selected(data){
      return data.map(item=>{
        const { check, isIndeterminate,children, ...rest } = item
        return (check || isIndeterminate) && {
          check,
          isIndeterminate,
          children: (children && children.length > 0) && this.selected(children),
          ...rest
        }
      }).filter(item=>item)
    },
    handleChangeOne(e){
      const data = e.map(item => {
        const { check, isIndeterminate,children, ...rest } = item
        const checkTotal = check
        return {
          check,
          isIndeterminate: checkTotal ? false : isIndeterminate,
          children: checkTotal ? this.setOneData(children,checkTotal) : children,
          ...rest
        }
      })
      this.$emit('change',data)
    },
    setOneData(data,checkTotal){
      return data.map(item=> {
        const { check,isIndeterminate,children, ...rest } = item
        return {
          check: true,
          ...rest,
          children: (checkTotal && children && children.length > 0) ? this.setOneData(children,checkTotal) : children,
          isIndeterminate: false
        }
      })
    },
    async handleChangeTwo(e) {
      let data = JSON.parse(JSON.stringify(this.regionData));
      const changeData = await this.setTwoData(e.data);

      const dataNew = data.map(item => {
        const {check, isIndeterminate, children, ...rest} = item
        return item.id === e.data[0].parentId ? {
          ...rest,
          check: e.data.every(item => item.check),
          isIndeterminate: e.isIndeterminate,
          children: changeData
        } : {
          ...item
        }
      })
      // console.error(123123123, dataNew)
      // // console.log(dataNew)
      this.$emit('change', dataNew)
    },
    setTwoData(data){
      // console.error(3333,data)
      return new Promise(resolve => {
        resolve(data.map(item=>{
          const { check, isIndeterminate,children, ...rest } = item
          return check ? {
            check,
            isIndeterminate: false,
            children: children.map(itemSon=>{
              const { check, ...rest } = itemSon
              return {
                check: true,
                ...rest
              }
            }),
            ...rest
          } : item
        }))
      })
    },
    async handleChangeThree(e) {
      // // console.log('handleChangeThree',e)
      // console.table(e.data)
      let data = JSON.parse(JSON.stringify(this.regionData));
      const dataNew = data.map(item => {
        const {check, isIndeterminate, children, ...rest} = item
        return children ? {
          check,
          isIndeterminate,
          children: this.setThreeData(children, e),
          ...rest
        } : {
          ...item
        }
      })
      // console.table(dataNew)
      // // console.log(111111,await this.getIsIndeterminate(dataNew))
      this.$emit('change', await this.getIsIndeterminate(dataNew))
    },
    setThreeData(data,e){
      return data.map(item=>{
        const { check, isIndeterminate,children, ...rest } = item
        return e.data[0].parentId && item.id === e.data[0].parentId ? {
          check: e.data.every(item=>item.check),
          isIndeterminate: e.isIndeterminate,
          children: e.data,
          ...rest
        } : {
          ...item
        }
      })
    },
    getIsIndeterminate(data){
      return new Promise(resolve => {
        resolve(data.map(item => {
          const { check,isIndeterminate,children, ...rest } = item;
          // // console.log(children.every(item=> item.check) || children.every(item=> !item.check))
          // // console.log(children.some(item=> item.isIndeterminate))
          return {
            check: children.every(item=> item.check),
            isIndeterminate: children.some(item=> item.isIndeterminate) ? true : (children.every(item=> item.check) || children.every(item=> !item.check)) ? false : children.some(item=> item.check) || children.some(item=> item.isIndeterminate),
            children,
            ...rest
          }
        }))
      })
    },
    handleClose() {
      this.$emit('close')
    },
    handleSubmit(){
      this.$emit('submit',{ type: this.type, index: this.index, data: this.regionDataNew })
    }
  }
}
</script>

<style lang="scss" scoped>
.region {
  /deep/ .el-card__header {
    background-color: #f2f2f2
  }
  /deep/ .el-card__body {
    padding: 10px !important;
  }
}

.region /deep/ .el-dialog__body {
  padding-top: 0 !important;
}
/deep/ .el-tree{
  height: 40vh;
  overflow:auto;
  padding: 10px 0;
}
</style>